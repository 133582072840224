import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MARKETING_HERO_CONSTANTS from 'organisms/MarketingHero/AU/Constants/MarketingConstants';
const { TRANSPARENT, FULLBLEED } = MARKETING_HERO_CONSTANTS;
export const useCanonicalUrl = () => {
  const useLocationHook = useLocation();
  const [canonicalUrl, setCanonicalUrl] = useState('');

  useEffect(() => {
    const origin = window?.location?.origin;
    const pathName = useLocationHook?.pathname ?? '';
    setCanonicalUrl(`${origin}${pathName}`);
  }, [useLocationHook?.pathname]);

  return { canonicalUrl };
};
export const useCarousalDataAU = (
  contentData,
  setMarketingAnalyticsOnLoad,
  setFullBleedImage,
  setTransparentImage,
  setVideoItem,
  currencyShortName,
  viewPromotionsVariables,
) => {
  const { nextSlideIndex, viewPromotionEventCall, setviewPromotionEventCall, isElementInViewport } =
    viewPromotionsVariables;
  useEffect(() => {
    const selectedData = contentData[nextSlideIndex];
    var ele = document.getElementsByClassName('slick-active')[0];
    if (!viewPromotionEventCall.includes(nextSlideIndex) && isElementInViewport(ele)) {
      setviewPromotionEventCall([...viewPromotionEventCall, nextSlideIndex]);
      setMarketingAnalyticsOnLoad(selectedData, currencyShortName);
    }

    if (selectedData && selectedData.backgroundImageType === FULLBLEED) {
      setFullBleedImage(true);
      setTransparentImage(false);
      setVideoItem(false);
    } else if (selectedData && selectedData.backgroundImageType === TRANSPARENT) {
      setFullBleedImage(false);
      setTransparentImage(true);
      setVideoItem(false);
    } else {
      setTransparentImage(false);
      setFullBleedImage(false);
      setVideoItem(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextSlideIndex]);
};
export const useCarousalDataIN = (
  contentData,
  setMarketingAnalyticsOnLoad,
  currencyShortName,
  viewPromotionsVariables,
) => {
  const { nextIndex, viewPromotionEventCall, setviewPromotionEventCall, isElementInViewport } = viewPromotionsVariables;
  useEffect(() => {
    const selectedData = contentData?.[nextIndex];
    var ele = document.getElementsByClassName('slick-active')[0];
    if (!viewPromotionEventCall.includes(nextIndex) && isElementInViewport(ele)) {
      setviewPromotionEventCall([...viewPromotionEventCall, nextIndex]);
      setMarketingAnalyticsOnLoad(selectedData, currencyShortName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextIndex]);
};
export const useShowDisposition = (showDisposition, carousal) => {
  useEffect(() => {
    !showDisposition && carousal?.current?.slickPlay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDisposition]);
};

export const useWiCodeFinaliseStatus = (userParams, authReducer, history, dispatch, getCustomerInfo) => {
  useEffect(() => {
    if (userParams?.loggedIn && !authReducer?.isLoading && history?.location?.isWicodeFinaliseFailed) {
      const reqPayload = {
        updateUserData: true,
      };
      dispatch(getCustomerInfo(reqPayload));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParams?.loggedIn, authReducer?.isLoading, dispatch, history?.location?.isWicodeFinaliseFailed]);
}

export const useSignedInAPI = (userParams, authReducer, signedIncall, dispatch, getCustomerInfo, setSignedIncall) => {
  useEffect(() => {
    if (userParams?.loggedIn && !authReducer?.isLoading && Boolean(signedIncall) === Boolean(userParams?.loggedIn)) {
      const reqPayload = {
        updateUserData: true,
      };
      dispatch(getCustomerInfo(reqPayload));
      if(signedIncall !== userParams?.loggedIn){
        setSignedIncall(userParams?.loggedIn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParams?.loggedIn, authReducer?.isLoading, dispatch]);
}